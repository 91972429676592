<template>
  <div class="services content py-sm-5 bg-custom">
    <div class="container-fluid pt-5 mt-5">
      <div class="row p-5 text-light">
        <!-- About Title -->
        <h1 class="title text-start fw-bold">Services & Contents</h1>
        <!-- Column -->
        <div class="col-sm col-md-7 px-5"></div>
        <div class="col-sm col-md-4">
          <img class="top" :src="work" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "Services",
  setup() {
    // Image source
    let imgsrc;
    let logo;
    let work;

    try {
      //Check availability
      imgsrc = ref(require("@/assets/images/gate.png"));
      logo = ref(require("@/assets/images/logo.svg"));
      work = ref(require("@/assets/images/work.jpg"));
    } catch (error) {
      console.error(error);
    }
    return {
      imgsrc,
      logo,
      work,
    };
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
h1,
h4 {
  color: #333;
}

div.services p {
  font-size: 18px;
  color: #fff;
  text-shadow: 1px 0 #888;
}
.logo {
  width: 380px;
}
.bg-custom {
}
p:first-child {
  color: #667eea;
}
img.top {
  width: 400px;
  height: auto;
}
div.card-pic {
  height: 100%;
  min-height: 235px;
}
div.card-frame {
  min-height: 235px;
  background-image: linear-gradient(to right, rgb(187, 225, 243), #3ac0d0);
}
div.card-frame p {
  color: #333;
  font-size: 16px;
  text-align: left !important;
}
img.card-content {
  margin-top: 16px !important;
  margin-left: 16px;
  max-width: 320px;
  max-height: 200px;
  margin-top: auto;
  margin-bottom: auto;
}

p {
  text-align: justify;
  letter-spacing: 3px;
  line-height: 1.6;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.Stars {
  background-color: #334155;
}

.slanted {
  transform: skewY(3deg);
  transform: skewX(-1deg);
}
.slanted > img {
  box-shadow: 10px 7px 10px black;
}
</style>
